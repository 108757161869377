/**
 * Inicializuje funkcionalitu rezervačného formuláru.
 */
const initReservationForm = () => {
  const form = document.getElementById("reservation-form")

  if (form !== null) {
    /**
     * Zobrazí zvolenú izbu.
     * @param name {string} Názov.
     * @param price {string} Cena.
     * @param imgUrl {string} Odkaz na obrázok.
     */
    const renderSelectedRoom = ({ name, price, imgUrl }) => {
      document.querySelector(".selected-room-col").insertAdjacentHTML(
        "beforeend",
        `<div class="booking__details-body">
            <h2 class="section__heading">${gettext("Vaša vybratá izba")}</h2>
            <figure class="room__details">
              <img src="${imgUrl}" class="img-responsive" alt="${gettext("Izba")} ${name}">
              <figcaption>
                <h3>${name}</h3>
                <div class="room__price">${price}&nbsp;€ <small>/ ${gettext("noc")}</small></div>
              </figcaption>
            </figure>
          </div>`
      )
    }

    const roomsData = JSON.parse(document.getElementById("rooms-data").textContent)
    const roomSelect = document.getElementById("id_room")

    // počiatočné zobrazenie vybratej izby
    if (roomSelect.value !== "") {
      renderSelectedRoom(roomsData[roomSelect.value])
    }

    // zobrazenie vybratej izby pri zmene hodnoty
    roomSelect.addEventListener("change", ({ target }) => {
      // odstráni predchádzajúce zobrazenie izby (ak existuje)
      const bookingDetails = document.querySelector(".booking__details-body")
      if (bookingDetails !== null) {
        bookingDetails.remove()
      }
      if (target.value !== "") {
        renderSelectedRoom(roomsData[target.value])
      }
    })
  }
}

export default initReservationForm
