import { Notyf } from "notyf"
import "notyf/notyf.min.css"

const notyf = new Notyf({
  duration: 3500,
  position: {
    x: "center",
  },
})

const initContactForm = () => {
  const form = document.getElementById("contact-form")

  if (form !== null) {
    const submitBtn = form.querySelector("button[type=submit]")
    window.submitContactForm = (token) => {
      form.addEventListener("submit", (evt) => {
        evt.preventDefault()
        submitBtn.disabled = true
        form.querySelector("#id_recaptcha_response").value = token
        fetch(form.action, {
          method: "post",
          body: new FormData(form),
        })
          .then((response) => response.json())
          .then(({ status, message }) => {
            if (status === "ok") {
              notyf.success(message)
              form.reset()
            } else if (status === "error") {
              notyf.error(message)
            }
            submitBtn.disabled = false
          })
      })
    }
  }
}

export default initContactForm
