const Isotope = require("isotope-layout")
/**
 * Template Theme JS
 */
;(function () {
  /*** Preloader ***/
  // Variables
  const $window = $(window)
  const loader = $("#loader-wrapper")

  // Methods
  $window.on({
    load: function () {
      loader.fadeOut()
    },
  })

  /*** Button to top page ***/
  // Variables
  const topButton = $("#back-to-top")
  let scrollTop = $(window).scrollTop()
  let isActive = false
  if (scrollTop > 100) {
    isActive = true
  }

  // Events
  $(window).scroll(function () {
    scrollTop = $(window).scrollTop()

    if (scrollTop > 100 && !isActive) {
      isActive = true
      topButton.fadeIn()
    } else if (scrollTop <= 100 && isActive) {
      isActive = false
      topButton.fadeOut()
    }
  })

  topButton.on("click", function (e) {
    e.preventDefault()
    window.scroll({
      top: 0,
      behavior: "smooth",
    })
  })

  /*** Smooth scroll to anchor ***/
  // Variables
  const link = $('a[href^="#section__"]')
  const duration = 1000

  // Methods
  function scrollTo(link) {
    const target = $(link.attr("href"))
    const navbar = $(".navbar")
    const navbarHeight = navbar.outerHeight()

    if (target.length) {
      $("html, body").animate(
        {
          scrollTop: target.offset().top - navbarHeight + 20,
        },
        duration
      )
    }
  }

  // Events
  link.on("click", function (e) {
    e.preventDefault()
    scrollTo($(this))
  })

  // /*** Count to ***/
  // // Variables
  // const statsItem = $(".section-data__nbr")
  //
  // // Methods
  // function init() {
  //   statsItem.each(function () {
  //     const $this = $(this)
  //
  //     $this.waypoint(
  //       function () {
  //         $this.not(".finished").countTo()
  //       },
  //       {
  //         offset: 350,
  //       }
  //     )
  //   })
  // }
  //
  // // Events
  // if (statsItem.length) {
  //   init()
  // }

  // /**
  //  * Alerts
  //  */
  // // Config
  // // ======
  //
  // const alertLifetime = 6000
  //
  // // Methods
  // // =======
  //
  // const generate = function (type, message) {
  //   const newAlert = $(
  //     '<div class="alert alert-' +
  //       type +
  //       ' fade in" id="page-alert">' +
  //       message +
  //       "</div>"
  //   )
  //
  //   // Append new alert
  //   $("body").append(newAlert)
  //
  //   // Remove new alert
  //   setTimeout(function () {
  //     newAlert.alert("close")
  //   }, alertLifetime)
  // }
  //
  // // Events
  // // ======
  //
  // $(document).on("sunset.alert.show", function (e, type, message) {
  //   generate(type, message)
  // })

  // /**
  //  * Newsletter
  //  */
  // // Variables
  // const $form = $("#mc-embedded-subscribe-form")
  //
  // // Proceed form
  // function register() {
  //   $.ajax({
  //     type: $form.attr("method"),
  //     url: $form.attr("action"),
  //     data: $form.serialize(),
  //     cache: false,
  //     dataType: "json",
  //     contentType: "application/json; charset=utf-8",
  //     error: function (err) {
  //       console.log(err)
  //       $(document).trigger("sunset.alert.show", [
  //         "danger",
  //         "Could not connect to the registration server. Please try again later.",
  //       ])
  //     },
  //     success: function (data) {
  //       // Remove message id
  //       const successMessage = data.msg.substr(0)
  //
  //       if (data.result !== "success") {
  //         $(document).trigger("sunset.alert.show", ["danger", successMessage])
  //       } else {
  //         // Show a confirmation
  //         $(document).trigger("sunset.alert.show", ["success", data.msg])
  //
  //         // Reset a form
  //         $form[0].reset()
  //       }
  //     },
  //   })
  // }
  //
  // // Events
  // $form.on("submit", function (e) {
  //   register()
  //
  //   e.preventDefault()
  // })

  /*** Navbar: Sticky ***/

  const sectionInfo = $(".section__info")
  const navbarDefault = $(".navbar-default")

  new Waypoint({
    element: sectionInfo,
    handler: function () {
      navbarDefault.toggleClass("navbar-sticky")
    },
    offset: function () {
      return -sectionInfo.height()
    },
  })

  // /*** Navbar: Sticky ***/
  //
  // $("#services__modal").on("shown.bs.modal", function () {
  //   $("#myInput").focus()
  // })

  // /*** Home parallax ***/
  //
  // const elem = $(".section__home"),
  //   elemHeight = elem.height(),
  //   parallaxRate = 2
  //
  // $(window).scroll(function () {
  //   const scrollTop = $(window).scrollTop(),
  //     elementOffsetTop = scrollTop,
  //     parallaxOffset = elementOffsetTop / parallaxRate
  //
  //   if (elementOffsetTop <= elemHeight) {
  //     $(".home-parallax__bg").css({
  //       "-webkit-transform": "translateY(" + parallaxOffset + "px)",
  //       transform: "translateY(" + parallaxOffset + "px)",
  //     })
  //   }
  // })

  /*** Dropdowns for Availability section ***/

  $(".reservation-dropdown").on("click", ".dropdown-menu > li > a", function () {
    const $this = $(this)

    const value = $this.text()

    const dropdownToggle = $this.parents(".dropdown").find(".dropdown-toggle")

    const reservationInput = $this.parents(".form-group").find("input")

    dropdownToggle.find("span").text(value)

    reservationInput.attr("value", parseInt(value), 10)

    dropdownToggle.dropdown("toggle")

    return false
  })

  /*** Best-rooms hover effect for iOS ***/
  $(".best-rooms__item").on({
    touchstart: function () {
      $(this).trigger("hover")
    },

    touchend: function () {
      $(this).trigger("hover")
    },
  })

  // /*** Calendar (for Availability Section) ***/
  //
  // const $Calendar = $(".form-control.date")
  //
  // if ($Calendar.length) {
  //   $Calendar.datetimepicker({
  //     icons: {
  //       time: "ion-android-time",
  //       date: "ion-android-calendar",
  //       up: " ion-chevron-up",
  //       down: "ion-chevron-down",
  //       previous: "ion-chevron-left",
  //       next: "ion-chevron-right",
  //       today: "ion-android-locate",
  //       clear: "ion-trash-b",
  //       close: "ion-close-round",
  //     },
  //     format: "DD MMMM YYYY",
  //   })
  // }

  let datetimepickerFormat = "M/DD/yyyy"

  if (currentLanguage === "sk") {
    datetimepickerFormat = "DD.MM.YYYY"
  }

  /*** DateTimePicker ***/
  $(".init-datetimepicker").datetimepicker({
    icons: {
      time: "ion-android-time",
      date: "ion-android-calendar",
      up: " ion-chevron-up",
      down: "ion-chevron-down",
      previous: "ion-chevron-left",
      next: "ion-chevron-right",
      today: "ion-android-locate",
      clear: "ion-trash-b",
      close: "ion-close-round",
    },
    locale: currentLanguage,
    format: datetimepickerFormat,
  })

  /*** Owl Carousel (For Gallery Section) ***/

  const $carouselGallery = $("#gallery__carousel")

  if ($carouselGallery.length) {
    $carouselGallery.owlCarousel({
      margin: 10,
      nav: true,
      slideSpeed: 150,
      animateOut: "fadeOut",
      responsiveClass: true,
      responsive: {
        0: {
          items: 1,
        },
        600: {
          items: 3,
        },
        1000: {
          items: 4,
        },
      },
    })
  }

  /*** Owl Carousel (For Testimonials Section) ***/

  // const $carouselTestimonials = $("#testimonials__carousel")
  //
  // if ($carouselTestimonials.length) {
  //   $carouselTestimonials.owlCarousel({
  //     margin: 10,
  //     slideSpeed: 150,
  //     responsiveClass: true,
  //     responsive: {
  //       0: {
  //         items: 1,
  //       },
  //       1000: {
  //         items: 2,
  //       },
  //     },
  //   })
  // }

  /*** Owl Carousel (For Room-detail Section) ***/

  const $carouselRoomDetail = $("#room-detail__carousel")

  if ($carouselRoomDetail.length) {
    $carouselRoomDetail.owlCarousel({
      items: 1,
      margin: 10,
      nav: false,
      animateOut: "fadeOut",
      slideSpeed: 150,
    })
  }

  /*** Smooth scroll to anchor ***/

  $('a[href*="#"]')
    .not('[href="#"], [data-slide], [data-toggle]')
    .on("click", function () {
      if (
        location.pathname.replace(/^\//, "") === this.pathname.replace(/^\//, "") &&
        location.hostname === this.hostname
      ) {
        let target = $(this.hash)
        target = target.length ? target : $("[name=" + this.hash.slice(1) + "]")

        if (target.length) {
          $("html, body").animate(
            {
              scrollTop: target.offset().top - 65,
            },
            1000
          )
          return false
        }
      }
    })

  /*** Gallery (filtering) ***/

  const $gallery = $("#gallery__items")

  if ($gallery.length) {
    // Init Isotope
    const isotope = new Isotope(document.getElementById("gallery__items"), {
      itemSelector: ".gallery__item",
      layoutMode: "masonry",
    })

    // layout Isotope after each image loads
    $gallery.imagesLoaded().progress(function () {
      isotope.layout()
    })

    // Set initial filtering
    isotope.arrange({ filter: ".all" })

    // Filter items on click
    $(".gallery-nav__link > a").on("click", function (e) {
      // Filter items
      const filterValue = $(this).attr("data-filter")

      isotope.arrange({ filter: filterValue })

      // Change active button
      $(this).parents("li").addClass("active").siblings("li").removeClass("active")

      e.preventDefault()
    })
  }
})()
