const navbarBrand = document.querySelector(".navbar-brand")

const scaleNavbarBrand = (scrollY) => {
  if (scrollY >= 100) {
    navbarBrand.classList.add("navbar-brand--scaled-down")
  } else {
    navbarBrand.classList.remove("navbar-brand--scaled-down")
  }
}

export { scaleNavbarBrand }
