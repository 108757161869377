import "./base.scss"
import initContactForm from "./contact_form"
import initReservationForm from "./reservation_form"
import throttle from "lodash/throttle"
import { scaleNavbarBrand } from "./navbar"

import $ from "jquery"

import "waypoints/lib/jquery.waypoints"

import "owl.carousel/dist/assets/owl.carousel.css"
import "owl.carousel/dist/assets/owl.theme.default.css"
import "owl.carousel"

import "lightbox2/src/css/lightbox.css"
import "lightbox2/src/js/lightbox"

import "imagesloaded"

import "eonasdan-bootstrap-datetimepicker"

// Bootstrap

// import "bootstrap-sass/assets/javascripts/bootstrap/affix"
// import "bootstrap-sass/assets/javascripts/bootstrap/alert"
// import "bootstrap-sass/assets/javascripts/bootstrap/button"
import "bootstrap-sass/assets/javascripts/bootstrap/carousel"
import "bootstrap-sass/assets/javascripts/bootstrap/collapse"
import "bootstrap-sass/assets/javascripts/bootstrap/dropdown"
// import "bootstrap-sass/assets/javascripts/bootstrap/modal"
// import "bootstrap-sass/assets/javascripts/bootstrap/tooltip"
// import "bootstrap-sass/assets/javascripts/bootstrap/popover"
// import "bootstrap-sass/assets/javascripts/bootstrap/scrollspy"
// import "bootstrap-sass/assets/javascripts/bootstrap/tab"
import "bootstrap-sass/assets/javascripts/bootstrap/transition"

// Sunset Hotel template
import "./sunset_hotel_template/sunset_hotel_template"

const initScrollBasedScripts = () => {
  scaleNavbarBrand(window.scrollY)

  window.addEventListener(
    "scroll",
    throttle(() => {
      scaleNavbarBrand(window.scrollY)
    })
  )
}

const onReady = (fn) => {
  if (document.readyState !== "loading") {
    fn()
  } else {
    document.addEventListener("DOMContentLoaded", fn)
  }
}

onReady(() => {
  initContactForm()
  initReservationForm()
  initScrollBasedScripts()
})
